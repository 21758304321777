import React from "react"
import { Router } from "@reach/router"
import Loadable from "react-loadable"
import Layout from "../components/layout"

const spinner = () => (<div className="spinner"></div>)
const Profile = Loadable({ loader: () => import("../containers/profile"), loading: spinner })
const AjouterAdresse = Loadable({ loader: () => import("../components/ajouterAdresse"), loading: spinner })
const OrderDetails = Loadable({ loader: () => import ("../containers/orderDetails"), loading: spinner })
const InitMDP = Loadable({ loader: () => import("../components/mot_de_passe/initMDP"), loading: spinner })
const ModifMDP = Loadable({ loader: () => import("../components/mot_de_passe/modifMDP"), loading: spinner })
const Commande = Loadable({ loader: () => import("../containers/commande"), loading: spinner })
const RecapPaiement = Loadable({ loader: () => import("../components/paiement/recapPaiement"), loading: spinner })
const Paypal = Loadable({ loader: () => import("../components/paiement/paypal"), loading: spinner })

const App = () => (
  <Layout banner={false}>
    <Router>
      <Profile path="/apps/mon-compte/" page="commandes" />
      <Profile path="/apps/mon-compte/commandes" page="commandes" />
      <Profile path="/apps/mon-compte/adresses" page="adresses" />
      <Profile path="/apps/mon-compte/donneesPerso" page="donneesPerso" />
      <AjouterAdresse path="/apps/mon-compte/ajout-adresse/:adresseSelected" />
      <OrderDetails path="/apps/mon-compte/commande/:id_order" />

      <InitMDP path="/apps/mon-compte/init-mdp" />
      <ModifMDP path="/apps/mon-compte/init-mdp/:token/:id_customer/:reset_token" />

      <Commande path="/apps/commande/" />
      <RecapPaiement path="/apps/commande/:module" />
      <Paypal path="/apps/commande/paiement/paypal" />
    </Router>
  </Layout>
)

export default App
